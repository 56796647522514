import axios from "axios";

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      const watcher = navigator.geolocation.watchPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
          navigator.geolocation.clearWatch(watcher); // Stops further tracking
        },
        (err) => {
          reject({
            latitude: null,
            longitude: null,
            error: err.message,
          });
        },
      );
    } else {
      reject({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  });
};

export const getLocationFromGoogle = async () => {
  try {
    const response = await axios.post(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GoogleAPIKEY}`,
      {},
    );
    return {
      latitude: response.data.location.lat,
      longitude: response.data.location.lng,
      error: null,
    };
  } catch (error) {
    return {
      latitude: null,
      longitude: null,
      error: error.message,
    };
  }
};

export const checkForNonEnglishCharacters = (name) => {
  const englishAlphabetsRegex = /^[a-zA-Z\s\-]*$/;

  return englishAlphabetsRegex.test(name) && name.trim() !== "";
}